import React from "react";

import gradienBG from "../assets/images/background/gradient-bg.png";
import bg2 from "../assets/images/background/course-graphics.svg";
import featuredimg2 from "../assets/images/svg/featured-img-2.svg";

import homeMain from "../assets/images/svg/home_main.svg";
import stream_line from "../assets/images/svg/stream_line.svg";
import stream_line2 from "../assets/images/svg/stream_line2.svg";

const SiteHomePage = () => {
  return (
    <div className="home-page">
      <section
        className="py-8 position-relative bg-cover"
        style={{ backgroundImage: `url(${gradienBG})` }}
      >
        <div className="container">
          <div className="row align-items-center mb-6">
            <div className="col-12 col-lg-7 order-md-2">
              <div className="mb-2 mb-md-0">
                <img
                  src={homeMain}
                  alt="graphics"
                  className="img-fluid mw-md-130"
                />
              </div>
            </div>
            <div className="col-12 col-lg-5 order-md-1">
              <h1 className="display-2 mb-5 fw-bold">
                Transform your business with STS
              </h1>

              <ul className="list-unstyled fs-3 text-dark mb-6 fw-medium">
                <li className="mb-1 d-flex">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                  </span>
                  <span>
                    Streamline Your Operations, Unlock Growth Potential
                  </span>
                </li>
                <li className="mb-1 d-flex">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                  </span>
                  <span>Seamless Processes, Unparalleled Expertise</span>
                </li>
                <li className="mb-1 d-flex">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                  </span>
                  <span>
                    Increased Customer Satisfaction, Accelerated Growth
                  </span>
                </li>
                <li className="mb-1 d-flex">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                  </span>
                  <span>Embrace the Future with Confidence</span>
                </li>
              </ul>

              <div className="mb-8 mb-lg-0">
                <a href="#" className="btn btn-primary me-3 mb-2 mb-lg-0">
                  Get started for Free
                </a>

                <a href="#" className="text-nowrap btn-link">
                  Questions? Talk to an expert
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8">
        <div className="container mt-lg-8">
          <div className="row">
            <div className="col-md-6 col-lg-3 col-12">
              <div className="mb-6 mb-lg-0 fs-4">
                <div className="icon-shape icon-lg bg-primary text-light-primary rounded-circle text-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-lightning-fill text-white"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path>
                  </svg>
                </div>
                <h3 className="fw-bold">Future-Proofing Your Business</h3>
                <p>
                  We help you identify and address potential challenges before
                  they become problems.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-12">
              <div className="mb-6 mb-lg-0 fs-4">
                <div className="icon-shape icon-lg bg-primary text-light-primary rounded-circle text-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-layers-fill text-white"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4z">
                      <path d="m2.125 8.567-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0l-5.17-2.756z"></path>
                    </path>
                  </svg>
                </div>
                <h3 className="fw-bold">Proactive Problem Solving</h3>
                <p>
                  We don't just react to issues, we anticipate them and develop
                  innovative solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-12">
              <div className="mb-6 mb-lg-0 fs-4">
                <div className="icon-shape icon-lg bg-primary text-light-primary rounded-circle text-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-phone text-white"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z">
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                    </path>
                  </svg>
                </div>
                <h3 className="fw-bold">Solutions for Complex Challenges</h3>
                <p>
                  We leverage creativity to tackle your toughest business
                  problems.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-12">
              <div className="mb-6 mb-lg-0 fs-4">
                <div className="icon-shape icon-lg bg-primary text-light-primary rounded-circle text-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-infinity text-white"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.68 5.792 7.345 7.75 5.681 9.708a2.75 2.75 0 1 1 0-3.916ZM8 6.978 6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978Zm.656.772 1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75Z"></path>
                  </svg>
                </div>
                <h3 className="fw-bold">Co-Creating Your Future</h3>
                <p>
                  We bring our innovative approach to the table, collaborating
                  with you to unlock new possibilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8">
        <div className="container">
          <div className="row align-items-center pb-lg-8">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-4 mb-lg-0 bg-gray-200 rounded-4">
                <img
                  src={stream_line}
                  alt="featured"
                  className="img-fluid w-100"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">
              <div className="ps-lg-7">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Streamline Operations, Unlock Growth.
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  Boost your business with our expertise in operational
                  efficiency!
                </h2>
                <h3>
                  Mauris interdum leo vel eleifend fringilla nibh elit interdc
                  nunc elementum nisi.
                </h3>
                <div className="mt-5 row">
                  <div className="col">
                    <ul className="list-unstyled fs-4 fw-medium">
                      <li className="mb-2 d-flex">
                        <span className="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-check-circle-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                          </svg>
                        </span>
                        <span>
                          Industry-Specific Solutions: We understand the unique
                          challenges faced by organizations across various
                          industries.
                        </span>
                      </li>
                      <li className="mb-2 d-flex">
                        <span className="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-check-circle-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                          </svg>
                        </span>
                        <span>
                          Workflow Optimization: We specialize in identifying
                          and streamlining workflows to maximize your
                          productivity.
                        </span>
                      </li>
                      <li className="mb-2 d-flex">
                        <span className="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-check-circle-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                          </svg>
                        </span>
                        <span>
                          Enhanced Efficiency: Our solutions are designed to
                          unlock your growth potential by optimizing your
                          operations.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-8" />
          <div className="row align-items-center py-lg-8">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6 ps-lg-6">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Seamless Processes, Unparalleled Expertise
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  We help you achieve operational excellence through:
                </h2>

                <div className="mt-6">
                  <div className="d-flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-star-fill text-primary mt-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                      </svg>
                    </div>
                    <div className="ms-3">
                      <h3 className="mb-2">
                        Uncover Improvement Opportunities:
                      </h3>
                      <p className="mb-0 fs-4">
                        Our team of experts will conduct a comprehensive
                        analysis of your current processes, pinpointing
                        bottlenecks and areas ripe for optimization.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-life-preserver mt-1 text-primary"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                      </svg>
                    </div>

                    <div className="ms-3">
                      <h3 className="mb-2">Industry-Leading Solutions:</h3>
                      <p className="mb-0 fs-4">
                        We leverage our deep understanding of industry best
                        practices to craft tailored solutions that streamline
                        your operations.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-file-earmark-text-fill text-primary mt-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"></path>
                      </svg>
                    </div>

                    <div className="ms-3">
                      <h3 className="mb-2 lh-1">Enhanced Efficiency:</h3>
                      <p className="mb-0 fs-4">
                        We eliminate redundancies and streamline workflows,
                        maximizing your productivity and empowering your team to
                        achieve more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0 bg-gray-200 rounded-4">
                <img
                  src={stream_line2}
                  alt="featured"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="py-8 bg-gray-200"
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div class="container my-lg-8">
          <div class="row justify-content-center text-center">
            <div class="col-md-9 col-12">
              <h2 class="display-4">
                Increased Customer Satisfaction, Accelerated Growth By
                optimizing your workflows
              </h2>
              <p class="lead px-lg-8 mb-6">
                you'll experience a significant reduction in turnaround times
                enabling you to deliver superior services and products to your
                customers. This heightened efficiency translates into enhanced
                customer satisfaction, expanded customer base, and accelerated
                growth for your business.
              </p>

              <div class="d-grid d-md-block">
                <a href="../sign-up.html" class="btn btn-primary mb-2 mb-md-0">
                  Contact US
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SiteHomePage;
