import logo from "./logo.svg";
import "./assets/fonts/feather/feather.css";
import "./assets/libs/bootstrap-icons/font/bootstrap-icons.min.css";
import "./assets/libs/simplebar/dist/simplebar.min.css";
import "./assets/css/theme.min.css";

import SiteHeader from "./layout/header";
import SiteHomePage from "./pages/homePage";

function App() {
  return (
    <div className="App">
      <SiteHeader />
      <SiteHomePage />
    </div>
  );
}

export default App;
