import React from "react";
import logo from "../assets/images/brand/logo/sts-2.svg";

const SiteHeader = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container px-0">
        <a className="navbar-brand" href="/">
          <img src={logo} className="img-fluid" />
        </a>
        <div className="d-flex align-items-center order-lg-3 ms-lg-3">
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <button
                className="btn btn-light btn-icon rounded-circle d-flex align-items-center"
                type="button"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                aria-label="Toggle theme (auto)"
              >
                <i className="bi theme-icon-active"></i>
                <span className="visually-hidden bs-theme-text">
                  Toggle theme
                </span>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end shadow"
                aria-labelledby="bs-theme-text"
              >
                <li>
                  <button
                    type="button"
                    className="dropdown-item d-flex align-items-center"
                    data-bs-theme-value="light"
                    aria-pressed="false"
                  >
                    <i className="bi theme-icon bi-sun-fill"></i>
                    <span className="ms-2">Light</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="dropdown-item d-flex align-items-center"
                    data-bs-theme-value="dark"
                    aria-pressed="false"
                  >
                    <i className="bi theme-icon bi-moon-stars-fill"></i>
                    <span className="ms-2">Dark</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="dropdown-item d-flex align-items-center active"
                    data-bs-theme-value="auto"
                    aria-pressed="true"
                  >
                    <i className="bi theme-icon bi-circle-half"></i>
                    <span className="ms-2">Auto</span>
                  </button>
                </li>
              </ul>
            </div>

            <a
              href="https://themes.getbootstrap.com/product/geeks-academy-admin-template/"
              className="btn btn-outline-primary mx-2 d-none d-md-block"
            >
              Sign in
            </a>
            <a
              href="https://themes.getbootstrap.com/product/geeks-academy-admin-template/"
              className="btn btn-primary d-none d-md-block"
            >
              Sign up
            </a>
          </div>
          <button
            className="navbar-toggler collapsed ms-2 ms-lg-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbar-default">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="landing-job.html">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarListing"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Listing
              </a>
              <ul
                className="dropdown-menu dropdown-menu-arrow"
                aria-labelledby="navbarListing"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="https://geeksui.codescandy.com/geeks/pages/jobs/job-listing.html"
                  >
                    List
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://geeksui.codescandy.com/geeks/pages/jobs/job-grid.html"
                  >
                    Grid
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://geeksui.codescandy.com/geeks/pages/jobs/job-single.html"
                  >
                    Single
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarPages"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Pages
              </a>
              <ul
                className="dropdown-menu dropdown-menu-arrow dropdown-menu-end"
                aria-labelledby="navbarPages"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="https://geeksui.codescandy.com/geeks/pages/jobs/company-list.html"
                  >
                    Company List
                  </a>
                </li>
                <li className="dropdown-submenu dropend">
                  <a
                    className="dropdown-item dropdown-list-group-item dropdown-toggle"
                    href="#"
                  >
                    Company Single
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://geeksui.codescandy.com/geeks/pages/jobs/company-about.html"
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://geeksui.codescandy.com/geeks/pages/jobs/company-reviews.html"
                      >
                        Reviews
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://geeksui.codescandy.com/geeks/pages/jobs/company-jobs.html"
                      >
                        Jobs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://geeksui.codescandy.com/geeks/pages/jobs/company-benefits.html"
                      >
                        Benifits
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://geeksui.codescandy.com/geeks/pages/jobs/company-photos.html"
                      >
                        Photos
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://geeksui.codescandy.com/geeks/pages/jobs/post-job.html"
                  >
                    Post A Job
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://geeksui.codescandy.com/geeks/pages/jobs/upload-resume.html"
                  >
                    Upload Resume
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://geeksui.codescandy.com/geeks/index.html"
              >
                Back to Demo
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fe fe-more-horizontal"></i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <div className="list-group">
                  <a
                    className="list-group-item list-group-item-action border-0"
                    href="https://geeksui.codescandy.com/geeks/docs/index.html"
                  >
                    <div className="d-flex align-items-center">
                      <i className="fe fe-file-text fs-3 text-primary"></i>
                      <div className="ms-3">
                        <h5 className="mb-0">Documentations</h5>
                        <p className="mb-0 fs-6">
                          Browse the all documentation
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    className="list-group-item list-group-item-action border-0"
                    href="https://geeksui.codescandy.com/geeks/docs/bootstrap-5-snippets.html"
                  >
                    <div className="d-flex align-items-center">
                      <i className="bi bi-files fs-3 text-primary"></i>
                      <div className="ms-3">
                        <h5 className="mb-0">Snippet</h5>
                        <p className="mb-0 fs-6">Bunch of Snippet</p>
                      </div>
                    </div>
                  </a>
                  <a
                    className="list-group-item list-group-item-action border-0"
                    href="https://geeksui.codescandy.com/geeks/docs/changelog.html"
                  >
                    <div className="d-flex align-items-center">
                      <i className="fe fe-layers fs-3 text-primary"></i>
                      <div className="ms-3">
                        <h5 className="mb-0">
                          Changelog
                          <span
                            className="text-primary ms-1"
                            id="changelog"
                          ></span>
                        </h5>
                        <p className="mb-0 fs-6">See what's new</p>
                      </div>
                    </div>
                  </a>
                  <a
                    className="list-group-item list-group-item-action border-0"
                    href="https://geeksui.codescandy.com/geeks-rtl/"
                    target="_blank"
                  >
                    <div className="d-flex align-items-center">
                      <i className="fe fe-toggle-right fs-3 text-primary"></i>
                      <div className="ms-3">
                        <h5 className="mb-0">RTL demo</h5>
                        <p className="mb-0 fs-6">RTL Pages</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SiteHeader;
